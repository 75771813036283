<template>
  <el-dialog
    title="租户配置"
    v-model="tanantDialog"
    :close-on-click-modal="false"
    :width="700"
    :before-close="handleClose"
  >
    <div class="header">
      <el-form
        :inline="true"
        :model="formInline"
        :rules="rules"
        size="mini"
        ref="ruleForm"
        class="header-left"
      >
        <el-form-item label="租户名称">
          <el-input
            clearable
            v-model="formInline.name"
            placeholder="请输入租户名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="租户标识">
          <el-input
            clearable
            v-model="formInline.ownership"
            placeholder="请输入租户标识"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="header-right">
        <el-button size="small" type="primary" @click="onSubmit"
          >查 询</el-button
        >
      </div>
    </div>
    <data-table
      :headerData="headerData"
      @setPage="setPage"
      @editor="editor"
      :tableData="list"
      :isEditor="true"
      height="300"
      @setCheck="setCheck"
      :checked="false"
      :totalElements="totalElements"
    ></data-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="handleClose">取 消</el-button>
        <el-button size="small" type="primary" @click="save">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { searchLessee } from '@/types/index'
import { searchParticipant, getLessee } from '@/utils/index'
import dataTable from '@/components/dataTable.vue'
import json2Form from '@/utils/common'
export default defineComponent({
  name: 'Home',
  data() {
    return {
      headerData: [
        { value: 'name', text: '租户名称' },
        { value: 'channelName', text: '渠道名称' },
        { value: 'ownership', text: '租户标识' }
      ]
    }
  },
  components: {
    dataTable
  },
  props: {
    type: String
  },
  setup(props, { emit }) {
    const formInline = reactive({
      name: '',
      ownership: '',
      p: 1,
      ps: 20
    })

    const ruleForm = ref()
    let tanantDialog = ref(false)

    let list: any = ref<any[]>([])
    let totalElements = ref<number>(0)
    let checkData = ref()

    // onMounted(() => {})

    //获取列表数据
    const getData = () => {
      list.value = []
      if (props.type === '0') {
        searchParticipant(json2Form(formInline)).then((res: any) => {
          list.value = list.value.concat(res.list)
          totalElements.value = res.total
        })
      } else if (props.type === '1') {
        let arr: {
          name: ''
          ownership: ''
          channelCode: ''
          channelName: ''
        }[] = []
        const data = JSON.parse(JSON.stringify(formInline))
        data.lesseeName = formInline.name
        data.ownerShip = formInline.ownership
        delete data.name
        delete data.ownership
        getLessee(json2Form(data)).then((res: any) => {
          res.content.forEach((element: any) => {
            arr.push({
              name: element.lesseeName,
              ownership: element.ownerShip,
              channelCode: element.channelCode,
              channelName: element.channelName
            })
          })
          list.value = arr
          totalElements.value = res.totalElements
        })
      }
    }

    // 查询
    const onSubmit = () => {
      if (ruleForm.value) {
        ruleForm.value.validate((valid: any) => {
          if (valid) {
            formInline.p = 1
            getData()
          } else {
            return false
          }
        })
      }
    }

    const save = () => {
      formInline.name = ''
      formInline.ownership = ''
      if (checkData.value) {
        emit('setTanantCheck', checkData.value, false)
      } else {
        ElMessage.error('请选择')
        return false
      }
      tanantDialog.value = !tanantDialog.value
    }
    const setCheck = (data: any) => {
      checkData.value = data
    }

    const close = () => {
      tanantDialog.value = !tanantDialog.value
      if (tanantDialog.value) {
        getData()
      }
    }
    const handleClose = () => {
      formInline.name = ''
      formInline.ownership = ''
      tanantDialog.value = !tanantDialog.value
      emit('setTanantCheck', null, true)
    }
    //分页
    const setPage = (page: number) => {
      formInline.p = page
      getData()
    }

    return {
      formInline,
      onSubmit,
      ruleForm,
      setPage,
      tanantDialog,
      list,
      totalElements,
      setCheck,
      save,
      close,
      handleClose
    }
  }
})
</script>
<style lang="scss" scoped>
.header {
  ::v-deep .el-input--suffix .el-input__inner {
    padding-right: 30px;
  }
}
.header {
  overflow: hidden;
  display: flex;
  .header-left {
    flex: 0 0 85%;
  }
  .header-right {
    flex: 0 0 15%;
    text-align: right;
  }
}
</style>
