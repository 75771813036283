<template>
  <div class="home">
    <header-title title="广告页面配置"></header-title>
    <div class="header">
      <el-form
        :inline="true"
        :model="formInline"
        size="mini"
        ref="ruleForm"
        class="header-left"
      >
        <el-form-item label="渠道类型">
          <el-select
            clearable
            v-model="formInline.channelCode"
            placeholder="请选择"
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in channel"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品名称">
          <el-input
            clearable
            v-model="formInline.productName"
            placeholder="请输入产品名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品编码">
          <el-input
            clearable
            v-model="formInline.productCode"
            placeholder="请输入产品编码"
          ></el-input>
        </el-form-item>
        <el-form-item label="页面名称">
          <el-select
            clearable
            filterable
            v-model="formInline.pageCode"
            placeholder="请选择"
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in pageCode"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否启用">
          <el-select
            clearable
            v-model="formInline.isEnable"
            placeholder="请选择"
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in isEnable"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="header-left">
        <el-button size="small" type="primary" @click="onSubmit('ruleForm')"
          >查 询</el-button
        >
        <el-button size="small" @click="add">新 增</el-button>
      </div>
    </div>
    <data-table
      :headerData="headerData"
      @setPage="setPage"
      @editor="editor"
      :tableData="list"
      :height="h"
      :isEditor="true"
      :totalElements="totalElements"
    ></data-table>
    <el-dialog
      title="新增/修改广告配置"
      v-model="dialogVisible"
      :close-on-click-modal="false"
      :width="600"
      :before-close="handleClose"
    >
      <el-form
        :inline="true"
        :model="productConfig"
        :rules="rules"
        size="mini"
        ref="dialogEditor"
        class="demo-form-inline"
      >
        <el-form-item label="渠道类型" prop="channelCode">
          <el-select
            :disabled="disabled"
            clearable
            v-model="productConfig.channelCode"
            placeholder="请选择"
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in channel"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品名称" prop="productName">
          <el-input
            v-model="productConfig.productName"
            placeholder="请输入产品名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品编码" prop="productCode">
          <el-input
            v-model="productConfig.productCode"
            placeholder="请输入产品编码"
          ></el-input>
        </el-form-item>
        <el-form-item label="页面名称" prop="pageCode">
          <el-select
            :disabled="disabled"
            clearable
            v-model="productConfig.pageCode"
            filterable
            placeholder="请选择"
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in pageCode"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否启用" prop="isEnable">
          <el-select
            clearable
            v-model="productConfig.isEnable"
            placeholder="请选择"
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in isEnable"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="save" :loading="loading"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
  nextTick
} from 'vue'
import { adConfig, productListConfig } from '@/types/index'
import { getReq, productList } from '@/utils/index'
import headerTitle from '@/components/headerTitle.vue'
import dataTable from '@/components/dataTable.vue'
import home from '@/views/home/home'
import json2Form, { setHeight } from '@/utils/common'
export default defineComponent({
  name: 'Home',
  data() {
    return {
      headerData: [
        { value: 'channelName', text: '渠道名称' },
        { value: 'productName', text: '产品名称' },
        { value: 'productCode', text: '产品编码' },
        { value: 'pageName', text: '页面名称' },
        { value: 'pageCode', text: '页面编码' },
        { value: 'isEnable', text: '是否启用' },
        { value: 'createdTime', text: '创建时间' }
      ],
      rules: {
        channelCode: [
          { required: true, message: '请选择渠道类型', trigger: 'change' }
        ],
        productName: [
          { required: true, message: '请输入产品名称', trigger: 'blur' }
        ],
        productCode: [
          { required: true, message: '请输入产品编码', trigger: 'blur' }
        ],
        pageCode: [
          { required: true, message: '请选择页面名称', trigger: 'change' }
        ],
        isEnable: [
          { required: true, message: '请选择处理状态类型', trigger: 'change' }
        ]
      }
    }
  },
  components: {
    headerTitle,
    dataTable
  },
  setup() {
    const formInline = reactive<productListConfig>({
      channelCode: '',
      productName: '',
      productCode: '',
      pageCode: '',
      isEnable: '',
      p: 1,
      ps: 20
    })

    //组件ref
    const ruleForm = ref()

    //基础信息
    const config = reactive<adConfig>({
      channel: [],
      isEnable: [],
      pageCode: []
    })
    let list: any = ref<any[]>([])
    let totalElements = ref<number>(0)
    let h = ref()
    // 编辑时页面与渠道不能编辑
    const disabled = ref(false)

    onMounted(() => {
      h.value = setHeight(true)
      getReq().then((res: any) => {
        const data: adConfig = res
        for (let key in data) {
          config[key] = data[key]
        }
      })
      getData()
    })

    //获取列表数据
    const getData = () => {
      list.value = []
      productList(json2Form(formInline)).then((res: any) => {
        if (res.content) {
          list.value = res.content
          totalElements.value = res.totalElements
        }
      })
    }
    //新增编辑
    const dialog = home(getData)
    // 查询
    const onSubmit = () => {
      if (ruleForm.value) {
        ruleForm.value.validate((valid: any) => {
          if (valid) {
            formInline.p = 1
            getData()
          } else {
            return false
          }
        })
      }
    }

    //新增
    const add = () => {
      disabled.value = false
      dialog.close(true)
      dialog.reset()
    }

    //
    const setPage = (page: number) => {
      formInline.p = page
      getData()
    }

    const editor = (res: any) => {
      disabled.value = true
      dialog.close(false)
      nextTick(() => {
        const data = JSON.parse(JSON.stringify(res))
        delete data.createUser
        delete data.createdTime
        delete data.updateUser
        delete data.updatedTime
        delete data.pageUrl
        dialog.productConfig.value = data
      })
    }

    return {
      ...toRefs(config),
      ...toRefs(dialog),
      formInline,
      onSubmit,
      ruleForm,
      setPage,
      add,
      list,
      totalElements,
      editor,
      h,
      disabled
    }
  }
})
</script>
<style lang="scss" scoped>
::v-deep .el-input--suffix .el-input__inner {
  padding-right: 13px;
}
.header {
  overflow: hidden;
  display: flex;
  .header-left {
    flex: 0 0 85%;
  }
  .header-right {
    flex: 0 0 15%;
    text-align: right;
  }
}
</style>
