const lineOption = {
  color: ['#7861D3', '#BAEA89', '#FFD76F', '#F49681'],
  title: {
    textStyle: {
      color: '#ACB3B3',
      fontSize: '12'
    }
  },
  legend: {},
  tooltip: {},
  grid: {
    show: false,
    left: '3%',
    right: '8%',
    bottom: '5%',
    height: '80%',
    containLabel: true
  },
  calculable: true,
  xAxis: {
    type: 'category',
    boundaryGap: false,
    splitLine: {
      show: false
    },
    axisLabel: {
      textStyle: {
        color: '#414141'
      }
    },
    axisLine: {
      lineStyle: {
        color: '#F2F2F2'
      }
    }
  },
  yAxis: {
    type: 'value'
  }
}

export { lineOption }
