<template>
  <div class="chart-base">
    <div id="chart" style="height: 250px; width: 100%"></div>
  </div>
</template>

<script lang="ts">
import { reactive, toRefs, ref, onMounted, defineComponent, watch } from 'vue'
import * as echarts from 'echarts'

export default defineComponent({
  name: 'chart',
  props: {
    option: Object,
    data: Object
  },
  setup(props, { emit }) {
    let instance = ref()

    const initEchart = () => {
      let doc = document.getElementById('chart')
      if (instance.value) {
        instance.value.clear()
      } else {
        if (doc) {
          instance.value = echarts.init(doc)
        }
      }
      instance.value.setOption(props.option)
    }

    const updateChart = () => {
      instance.value.setOption(props.data)
    }

    const updateSize = () => {
      instance.value && instance.value.resize()
    }

    watch(
      () => props.data,
      (newProps) => {
        if (newProps) {
          updateChart()
        }
      }
    )

    watch(
      () => props.option,
      (newProps) => {
        if (newProps) {
          instance.value.setOption(newProps)
        }
      }
    )
    onMounted(() => {
      setTimeout(() => {
        initEchart()
        props.data && Object.keys(props.data).length && updateChart()
        window.addEventListener('resize', updateSize)
      })
    })
    const getDataURL = () => {
      let img = instance.value.getDataURL()
    }
    return {
      getDataURL
    }
  }
})
</script>
<style scoped>
.chart-base {
  margin-bottom: 15px;
}
</style>
