
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
  nextTick
} from 'vue'
import { adConfig, tenantConfig } from '@/types/index'
import { advertList, getAdvertReq } from '@/utils/index'
import headerTitle from '@/components/headerTitle.vue'
import dataTable from '@/components/dataTable.vue'
import dialogFn from '@/views/tenantMsgConfig/index'
import tenantDataConfig from '@/components/tenantDataConfig.vue'
import pageConfig from '@/components/pageConfig.vue'
import json2Form, { setHeight } from '@/utils/common'
export default defineComponent({
  name: 'Home',
  data() {
    return {
      headerData: [
        { value: 'channelName', text: '渠道名称' },
        { value: 'lesseeName', text: '租户名称' },
        { value: 'ownerShip', text: '租户标识' },
        { value: 'timeLimit', text: '投放时段' },
        { value: 'size', text: '尺寸' },
        { value: 'isEnable', text: '是否启用' },
        { value: 'pageName', text: '页面名称' },
        { value: 'pageCode', text: '页面编码' },
        { value: 'adTypeName', text: '广告类型名称' },
        { value: 'adTypeCode', text: '广告类型编码' }
      ],
      rules: {
        lesseeName: [
          { required: true, message: '请输入租户名称', trigger: 'change' }
        ],
        adWidth: [
          { required: true, message: '请输入广告位分辨率宽度', trigger: 'blur' }
        ],
        adHeight: [
          { required: true, message: '请输入广告位分辨率高度', trigger: 'blur' }
        ],
        pageCode: [
          { required: true, message: '请选择页面编码', trigger: 'change' }
        ],
        adTypeCode: [
          { required: true, message: '请选择广告类型', trigger: 'change' }
        ],
        timeLimit: [
          { required: true, message: '请选择处理状态类型', trigger: 'change' }
        ],
        startTime: [
          { required: true, message: '请选择时间', trigger: 'change' }
        ]
      }
    }
  },
  components: {
    headerTitle,
    dataTable,
    tenantDataConfig,
    pageConfig
  },
  setup() {
    const formInline = reactive<tenantConfig>({
      lesseeName: '',
      channel: '',
      ownerShip: '',
      isEnable: '',
      p: 1,
      ps: 20
    })

    //组件ref
    const ruleForm = ref()
    //基础信息
    const config = reactive<adConfig>({
      channel: [],
      isEnable: [],
      adType: [],
      page: [],
      ownerShip: []
    })

    let list = ref<any[]>([])
    let totalElements = ref<number>(0)
    let h = ref()
    const type = ref(false)
    const tanantConfig = ref()
    const inputRef = ref()
    const inputAddRef = ref()
    const channelCode = ref()

    onMounted(() => {
      h.value = setHeight(true)
      getAdvertReq().then((res: any) => {
        const data: adConfig = res
        for (let key in data) {
          config[key] = data[key]
        }
      })
      getData()
    })

    //获取列表数据
    const getData = () => {
      list.value = []
      advertList(json2Form(formInline)).then((res: any) => {
        if (res.content) {
          list.value = res.content
          totalElements.value = res.totalElements
        }
      })
    }
    //新增编辑
    const dialog = dialogFn(getData)
    // 查询
    const onSubmit = () => {
      if (ruleForm.value) {
        ruleForm.value.validate((valid: any) => {
          if (valid) {
            formInline.p = 1
            getData()
          } else {
            return false
          }
        })
      }
    }

    //新增
    const add = () => {
      channelCode.value = ''
      dialog.reset()
      dialog.close(true)
    }

    //
    const setPage = (page: number) => {
      formInline.p = page
      getData()
    }

    const editor = (res: any) => {
      dialog.close(false)
      nextTick(() => {
        const data = JSON.parse(JSON.stringify(res))
        delete data.createUser
        delete data.createdTime
        delete data.updateUser
        delete data.updatedTime
        delete data.createUserName
        delete data.startTimeStr
        delete data.endTimeStr

        dialog.productConfig.value = data
        if (data.startTime) {
          dialog.time.value = [data.startTime, data.endTime]
        } else {
          dialog.time.value = []
        }
        dialog.productConfig.value.startTime = data.startTime
        dialog.productConfig.value.endTime = data.endTime
      })
    }

    // 选中租户
    const setTanantCheck = (data: any, bool: boolean) => {
      if (bool) {
        if (type.value) {
          // eslint-disable-next-line no-constant-condition
          if (dialog.productConfig.value.ownerShip === ' ') {
            dialog.productConfig.value.ownerShip = ''
            dialog.productConfig.value.channel = ''
            dialog.productConfig.value.lesseeName = ''
            dialog.productConfig.value.pageName = ''
          }
        }
      } else {
        if (type.value) {
          dialog.productConfig.value.ownerShip = data.ownership
          dialog.productConfig.value.channel = data.channelCode
          dialog.productConfig.value.lesseeName = data.name
          dialog.productConfig.value.pageName = data.pageName
          channelCode.value = data.channelCode
        } else {
          formInline.lesseeName = data.name
          formInline.ownerShip = data.ownership
        }
      }
    }
    const focus = () => {
      inputRef.value.blur()
      type.value = false
      tanantConfig.value.close()
    }
    const addFocus = () => {
      dialog.productConfig.value.ownerShip = ' '
      inputAddRef.value.blur()
      type.value = true
      tanantConfig.value.close()
    }
    const clear = () => {
      if (type.value) {
        dialog.productConfig.value.ownerShip = ''
        dialog.productConfig.value.channel = ''
        dialog.productConfig.value.lesseeName = ''
        dialog.productConfig.value.pageName = ''
      } else {
        formInline.lesseeName = ''
        formInline.ownerShip = ''
      }
    }
    return {
      ...toRefs(dialog),
      ...toRefs(config),
      formInline,
      onSubmit,
      ruleForm,
      setPage,
      add,
      list,
      totalElements,
      editor,
      h,
      tanantConfig,
      focus,
      addFocus,
      inputRef,
      inputAddRef,
      clear,
      setTanantCheck,
      channelCode
    }
  }
})
