<template>
  <el-dialog
    title="租户配置"
    v-model="tanantDialog"
    :close-on-click-modal="false"
    :width="700"
    :before-close="handleClose"
  >
    <div class="header">
      <el-form
        :inline="true"
        :model="formInline"
        :rules="rules"
        size="mini"
        ref="ruleForm"
        class="header-left"
      >
        <el-form-item label="页面名称">
          <el-input
            clearable
            v-model="formInline.pageName"
            placeholder="请输入页面名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="header-right">
        <el-button size="small" type="primary" @click="onSubmit"
          >查 询</el-button
        >
      </div>
    </div>
    <data-table
      :headerData="headerData"
      @setPage="setPage"
      @editor="editor"
      :tableData="list"
      :isEditor="true"
      height="300"
      @setCheck="setCheck"
      :checked="false"
      :totalElements="totalElements"
    ></data-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="handleClose">取 消</el-button>
        <el-button size="small" type="primary" @click="save">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, watch } from 'vue'
import { ElMessage } from 'element-plus'
import { searchLessee } from '@/types/index'
import { productList } from '@/utils/index'
import dataTable from '@/components/dataTable.vue'
import json2Form from '@/utils/common'
export default defineComponent({
  name: 'Home',
  data() {
    return {
      headerData: [
        { value: 'pageName', text: '页面名称' },
        { value: 'pageCode', text: '页面编码' },
        { value: 'channelName', text: '渠道名称' }
      ]
    }
  },
  components: {
    dataTable
  },
  props: {
    type: String,
    channelCode: String
  },
  setup(props, { emit }) {
    console.log(props.channelCode)

    const formInline = reactive({
      pageName: '',
      channelCode: '',
      p: 1,
      ps: 20
    })

    const ruleForm = ref()
    let tanantDialog = ref(false)

    let list: any = ref<any[]>([])
    let totalElements = ref<number>(0)
    let checkData = ref()

    // onMounted(() => {})
    watch(
      () => props.channelCode,
      (newprops) => {
        formInline.channelCode = newprops || ''
      }
    )
    //获取列表数据
    const getData = () => {
      list.value = []
      productList(json2Form(formInline)).then((res: any) => {
        if (res.content) {
          list.value = list.value.concat(res.content)
          totalElements.value = res.totalElements
        }
      })
    }

    // 查询
    const onSubmit = () => {
      if (ruleForm.value) {
        ruleForm.value.validate((valid: any) => {
          if (valid) {
            formInline.p = 1
            getData()
          } else {
            return false
          }
        })
      }
    }

    const save = () => {
      formInline.pageName = ''
      if (checkData.value) {
        emit('setPageCheck', checkData.value, false)
      } else {
        ElMessage.error('请选择')
        return false
      }
      tanantDialog.value = !tanantDialog.value
    }
    const setCheck = (data: any) => {
      checkData.value = data
    }

    const close = () => {
      tanantDialog.value = !tanantDialog.value
      if (tanantDialog.value) {
        getData()
      }
    }
    const handleClose = () => {
      formInline.pageName = ''
      tanantDialog.value = !tanantDialog.value
      emit('setTanantCheck', null, true)
    }
    //分页
    const setPage = (page: number) => {
      formInline.p = page
      getData()
    }

    return {
      formInline,
      onSubmit,
      ruleForm,
      setPage,
      tanantDialog,
      list,
      totalElements,
      setCheck,
      save,
      close,
      handleClose
    }
  }
})
</script>
<style lang="scss" scoped>
.header {
  overflow: hidden;
  display: flex;
  .header-left {
    flex: 0 0 85%;
  }
  .header-right {
    flex: 0 0 15%;
    text-align: right;
  }
}
</style>
