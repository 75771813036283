<!--
 * @Author: your name
 * @Date: 2021-07-08 09:37:27
 * @LastEditTime: 2021-07-21 10:41:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \advertising-platform\src\components\dataTable.vue
-->
<template>
  <el-table
    :header-cell-style="setBackground"
    :data="newTableData"
    :height="getHeight"
    style="width: 100%"
  >
    <template v-for="item in headerData" :key="item.value">
      <el-table-column
        v-if="item.value === 'isEnable'"
        :prop="item.value"
        :label="item.text"
      >
        <template #default="scope">
          <div v-if="scope.row.isEnable === '1'">是</div>
          <div v-else>否</div>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="item.value === 'openFlag'"
        :prop="item.value"
        :label="item.text"
      >
        <template #default="scope">
          <div v-if="scope.row.openFlag === '1'">是</div>
          <div v-else>否</div>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="item.value === 'size'"
        :prop="item.value"
        :label="item.text"
        width="180"
      >
        <template #default="scope">
          <div>高：{{ scope.row.adHeight }} 宽：{{ scope.row.adWidth }}</div>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="item.value === 'clickFlag'"
        :prop="item.value"
        :label="item.text"
      >
        <template #default="scope">
          <div v-if="scope.row.clickFlag === '1'">是</div>
          <div v-else>否</div>
        </template>
      </el-table-column>
      <el-table-column
        v-else-if="item.value === 'timeLimit'"
        :prop="item.value"
        :label="item.text"
        width="180"
      >
        <template #default="scope">
          <div v-if="scope.row.timeLimit === '0'">无限制</div>
          <div v-else>
            每天{{ changetime(scope.row.startTime) }}-{{
              changetime(scope.row.endTime)
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column v-else :prop="item.value" :label="item.text">
      </el-table-column>
    </template>
    <el-table-column fixed="right" label="操作" width="180" v-if="isEditor">
      <template #default="scope">
        <el-button
          v-if="checked"
          @click="handleClick(scope.row)"
          type="text"
          size="small"
          >修改</el-button
        >
        <el-checkbox
          v-else
          @change="getData(scope.row, scope.$index)"
          v-model="scope.row.checkbox"
        ></el-checkbox>
      </template>
    </el-table-column>
  </el-table>
  <div class="pagination">
    <span class="total">总记录：{{ totalElements }}条</span>
    <el-pagination
      @current-change="currentChange"
      :page-size="20"
      background
      layout="prev, pager, next"
      :total="totalElements"
    >
    </el-pagination>
  </div>
</template>

<script lang="ts">
import { ref, computed, defineComponent, watch } from 'vue'
import { changetime } from '@/utils/common'
export default defineComponent({
  name: 'dataTable',
  props: {
    tableData: Object,
    headerData: Object,
    totalElements: Number,
    isEditor: Boolean,
    height: Number,
    checked: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const newTableData = ref()

    const setBackground = () => {
      return 'background:#F2F2F2'
    }
    const currentChange = (page: number) => {
      // eslint-disable-next-line no-debugger
      emit('setPage', page)
    }
    const handleClick = (res: any) => {
      emit('editor', res)
    }
    const getData = (res: any, index: number) => {
      if (newTableData.value) {
        newTableData.value.forEach((element: any) => {
          element.checkbox = false
        })
      }
      newTableData.value[index].checkbox = true
      emit('setCheck', newTableData.value[index])
    }
    watch(
      () => props.tableData,
      (newProps) => {
        if (newProps && newProps.length > 0) {
          newProps.forEach((element: any) => {
            element.checkbox = false
          })
        }
        newTableData.value = newProps
      }
    )

    const getHeight = computed(() => {
      return (
        props.height &&
        props.height -
          document.getElementsByClassName('pagination')[0].clientHeight +
          'px'
      )
    })

    return {
      setBackground,
      currentChange,
      handleClick,
      changetime,
      getHeight,
      getData,
      newTableData
    }
  }
})
</script>

<style lang="scss" scoped>
.pagination {
  margin-top: 22px;
  &::after {
    display: block;
    height: 0;
    clear: both;
    content: '';
  }
  span {
    float: left;
    margin-top: 6px;
  }
  ::v-deep .el-pagination {
    float: right;
    .number {
      border: 1px solid #e3e2e2;
      background: #fff;
    }
  }
}
::v-deep .el-table__header-wrapper {
  background: #f2f2f2;
}
</style>
