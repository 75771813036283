<template>
  <div class="title">
    <i></i><span>{{ title }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'headerTitle',
  props: {
    title: String
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.title {
  margin-bottom: 23px;
  i {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #3b95e9;
  }
  span {
    margin-left: 11px;
    font-size: 14px;
  }
}
</style>
