/*
 * @Author: your name
 * @Date: 2021-07-08 16:03:35
 * @LastEditTime: 2021-07-22 13:55:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \advertising-platform\src\views\home\home.ts
 */
import { ref } from 'vue'
import { productConfig } from '@/types/index'
import { productSave } from '@/utils/index'
import json2Form from '@/utils/common'

export default function dialog(fn: () => void): any {
  const productConfig = ref<productConfig>({
    channelCode: '',
    productName: '',
    productCode: '',
    pageCode: '',
    pageUrl: '',
    isEnable: ''
  })

  const dialogEditor = ref()

  const dialogVisible = ref<boolean>(false)
  const type = ref(true)
  const loading = ref(false)

  const save = () => {
    if (dialogEditor.value) {
      if (type.value) {
        if (productConfig.value.id) {
          delete productConfig.value.id
        }
      }
      loading.value = true
      dialogEditor.value.validate((valid: any) => {
        if (valid) {
          productSave(json2Form(productConfig.value)).then((res) => {
            dialogEditor.value.resetFields()
            setTimeout(() => {
              loading.value = false
            }, 2000)

            fn()
            close(type.value)
          }).catch(err => {
            setTimeout(() => {
              loading.value = false
            }, 2000)
          })
        } else {
          loading.value = false
          return false
        }
      })
    }
  }

  const close = (bool: boolean) => {
    type.value = bool
    dialogVisible.value = !dialogVisible.value
  }
  const reset = () => {
    setTimeout(() => {
      dialogEditor.value.resetFields()
    })
  }

  return { productConfig, dialogVisible, dialogEditor, save, close, reset, loading }
}
