<template>
  <div class="home">
    <header-title title="租户基础信息配置"></header-title>
    <div class="header">
      <el-form
        :inline="true"
        :model="formInline"
        :rules="rules"
        size="mini"
        ref="ruleForm"
        class="header-left"
      >
        <el-form-item label="渠道类型">
          <el-select
            clearable
            v-model="formInline.channelCode"
            placeholder="请选择"
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in channel"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="租户名称">
          <el-input
            @focus="focus"
            @clear="clear"
            ref="inputRef"
            clearable
            v-model="formInline.lesseeName"
            placeholder="请输入租户名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否开启广告">
          <el-select
            clearable
            v-model="formInline.openFlag"
            placeholder="请选择"
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in openFlagList"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="header-right">
        <el-button size="small" type="primary" @click="onSubmit"
          >查 询</el-button
        >
        <el-button size="small" @click="add">新 增</el-button>
      </div>
    </div>
    <data-table
      :headerData="headerData"
      @setPage="setPage"
      @editor="editor"
      :tableData="list"
      :isEditor="true"
      :height="h"
      :totalElements="totalElements"
    ></data-table>
    <el-dialog
      title="新增/修改广告配置"
      v-model="dialogVisible"
      :close-on-click-modal="false"
      :width="650"
      :before-close="cancel"
    >
      <el-form
        :inline="true"
        :model="lesseeConfig"
        label-position="right"
        label-width="110px"
        :rules="rules"
        size="mini"
        ref="dialogEditor"
        class="demo-form-inline"
      >
        <el-form-item label="渠道类型" prop="channelCode">
          <el-select
            :disabled="disabled"
            clearable
            v-model="lesseeConfig.channelCode"
            placeholder="请选择渠道类型"
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in channel"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="租户名称" prop="lesseeName">
          <el-input
            ref="inputAddRef"
            @focus="addFocus"
            @clear="clear"
            :disabled="disabled"
            v-model="lesseeConfig.lesseeName"
            placeholder="请输入租户名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="广告位id" prop="adId">
          <el-input
            v-model="lesseeConfig.adId"
            placeholder="请输入广告位id"
          ></el-input>
        </el-form-item>
        <el-form-item label="app-Key" prop="appKey">
          <el-input
            v-model="lesseeConfig.appKey"
            placeholder="请输入app-Key"
          ></el-input>
        </el-form-item>
        <el-form-item label="appSecret" prop="appSecret">
          <el-input
            v-model="lesseeConfig.appSecret"
            placeholder="请输入appSecret"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否开启广告" prop="openFlag">
          <el-select
            clearable
            v-model="lesseeConfig.openFlag"
            placeholder="请选择"
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in openFlagList"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="save" :loading="loading"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <tenant-data-config
      ref="tanantConfig"
      type="0"
      @setTanantCheck="setTanantCheck"
    ></tenant-data-config>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  toRefs,
  nextTick
} from 'vue'
import { adConfig, lesseeListConfig } from '@/types/index'
import { getLesseeReq, lesseeList } from '@/utils/index'
import headerTitle from '@/components/headerTitle.vue'
import dataTable from '@/components/dataTable.vue'
import tenantDataConfig from '@/components/tenantDataConfig.vue'
import tenantConfig from '@/views/tenantConfig/tenantConfig'
import json2Form, { setHeight } from '@/utils/common'
export default defineComponent({
  name: 'Home',
  data() {
    return {
      rules: {
        channelCode: [
          { required: true, message: '请选择渠道类型', trigger: 'change' }
        ],
        lesseeName: [
          { required: true, message: '请输入租户名称', trigger: 'blur' }
        ],
        adId: [{ required: true, message: '广告位id', trigger: 'blur' }],
        pageCode: [
          { required: true, message: '请输入页面编码', trigger: 'blur' }
        ],
        appKey: [{ required: true, message: '请输入app-Key', trigger: 'blur' }],
        appSecret: [
          { required: true, message: '请输入appSecret', trigger: 'blur' }
        ],
        openFlag: [
          { required: true, message: '请选择是否开启广告', trigger: 'change' }
        ]
      },
      headerData: [
        { value: 'channelName', text: '渠道名称' },
        { value: 'lesseeName', text: '租户名称' },
        { value: 'ownerShip', text: '租户标识' },
        { value: 'adId', text: '广告位id' },
        { value: 'appKey', text: 'app-key' },
        { value: 'appSecret', text: 'appSecret' },
        { value: 'openFlag', text: '状态' },
        { value: 'createdTime', text: '创建时间' }
      ]
    }
  },
  components: {
    headerTitle,
    dataTable,
    tenantDataConfig
  },
  setup() {
    const formInline = reactive<lesseeListConfig>({
      channelCode: '',
      lesseeName: '',
      ownerShip: '',
      openFlag: '',
      p: 1,
      ps: 20
    })

    const ruleForm = ref()
    const tanantConfig = ref()
    const type = ref(false)
    const inputRef = ref()
    const inputAddRef = ref()
    // 编辑时租户与渠道不能编辑
    const disabled = ref(false)
    //基础信息
    let channel = ref<{ value: string; key: string }[]>([])

    let openFlagList = ref<{ value: string; key: string }[]>([])

    let list: any = ref<any[]>([])
    let totalElements = ref<number>(0)
    let h = ref()

    onMounted(() => {
      h.value = setHeight(true)
      getLesseeReq().then((res: any) => {
        const data: adConfig = res
        channel.value = data['channel']
        openFlagList.value = data['openFlag']
      })
      getData()
    })

    //获取列表数据
    const getData = () => {
      list.value = []
      lesseeList(json2Form(formInline)).then((res: any) => {
        if (res.content) {
          list.value = res.content
          totalElements.value = res.totalElements
        }
      })
    }
    //新增编辑
    const dialog = tenantConfig(getData)
    // 查询
    const onSubmit = () => {
      if (ruleForm.value) {
        ruleForm.value.validate((valid: any) => {
          if (valid) {
            formInline.p = 1
            getData()
          } else {
            return false
          }
        })
      }
    }

    //新增
    const add = () => {
      type.value = true
      disabled.value = false
      dialog.reset()
      dialog.close(true)
    }

    //
    const setPage = (page: number) => {
      formInline.p = page
      getData()
    }
    // 编辑
    const editor = (res: any) => {
      type.value = true
      disabled.value = true
      dialog.close(false)
      nextTick(() => {
        const data = JSON.parse(JSON.stringify(res))
        delete data.createUser
        delete data.createdTime
        delete data.updateUser
        delete data.updatedTime
        delete data.ratio
        dialog.lesseeConfig.value = data
      })
    }

    // 选中租户
    const setTanantCheck = (data: any, bool: boolean) => {
      if (bool) {
        if (type.value) {
          // eslint-disable-next-line no-constant-condition
          if (dialog.lesseeConfig.value.lesseeName === ' ') {
            dialog.lesseeConfig.value.lesseeName = ''
            dialog.lesseeConfig.value.ownerShip = ''
          }
        }
      } else {
        if (type.value) {
          dialog.lesseeConfig.value.lesseeName = data.name
          dialog.lesseeConfig.value.ownerShip = data.ownership
        } else {
          formInline.lesseeName = data.name
          formInline.ownerShip = data.ownership
        }
      }
    }
    const focus = () => {
      inputRef.value.blur()
      type.value = false
      tanantConfig.value.close()
    }
    const addFocus = () => {
      if (!dialog.lesseeConfig.value.lesseeName) {
        dialog.lesseeConfig.value.lesseeName = ' '
      }
      inputAddRef.value.blur()
      type.value = true
      tanantConfig.value.close()
    }
    const clear = () => {
      if (type.value) {
        dialog.lesseeConfig.value.lesseeName = ''
        dialog.lesseeConfig.value.ownerShip = ''
      } else {
        formInline.lesseeName = ''
        formInline.ownerShip = ''
      }
    }

    return {
      ...toRefs(dialog),
      formInline,
      onSubmit,
      ruleForm,
      setPage,
      add,
      channel,
      openFlagList,
      list,
      totalElements,
      editor,
      h,
      setTanantCheck,
      focus,
      tanantConfig,
      addFocus,
      inputRef,
      inputAddRef,
      clear,
      disabled
    }
  }
})
</script>
<style lang="scss" scoped>
::v-deep .el-input--suffix .el-input__inner {
  padding-right: 15px;
}
.header {
  overflow: hidden;
  display: flex;
  .header-left {
    flex: 0 0 85%;
  }
  .header-right {
    flex: 0 0 15%;
    text-align: right;
  }
}
</style>
