
import axios from 'axios'
import { ElMessage } from 'element-plus'
const instance = axios.create()
const TIMEOUT = 120 * 1000

instance.defaults.timeout = TIMEOUT
instance.defaults.baseURL = process.env.BASE_URL

// 处理展示错误信息

// POST传参序列化(添加请求拦截器)
instance.interceptors.request.use(
  (config: any) => {
    if (localStorage.getItem('token')) {
      // 把localStorage的token放在Authorization里

      config.headers.token = localStorage.getItem('token')
    }
    return config
  },
  (error: any) => {
    return Promise.reject(error)
  }
)

// 返回状态判断(添加响应拦截器)
// 接口异常默认调用 Message.error()，若不需要异常提示，请携带hideMessage参数
instance.interceptors.response.use(
  (res: any) => {
    const data: any = res.data
    const code: string = data.responseCode
    if (
      res.config.url === 'api/adReport/lesseeReport/exportXls' ||
      res.config.url === 'api/adLog/list/exportXls'
    ) {
      return res.data
    }
    if (code === '100000') {
      return Promise.resolve(data.result)
    } else {
      switch (code) {
        case '100000':
          return Promise.resolve(data)
        case '101002':
          return Promise.reject(data)
        default:
          ElMessage.error(data.message);
          return Promise.reject(data)
      }
    }
  },
  (error: any) => {
    const data = {
      responseCode: '555555',
      args: '',
      result: {},
      message:
        error.code === 'ECONNABORTED'
          ? '请求超时，请稍后再试'
          : '网络异常，请稍后再试'
    }
    return Promise.reject(data)
  }
)
export default instance
