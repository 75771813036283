/*
 * @Author: your name
 * @Date: 2021-07-08 16:27:23
 * @LastEditTime: 2021-07-29 11:22:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \advertising-platform\src\views\tenantConfig\tenantConfig.ts
 */

import { ref } from 'vue'
import { lesseeSave } from '@/utils/index'
import { lesseeConfig } from '@/types/index'
import json2Form from '@/utils/common'
export default function dialog(fn: () => void): any {
  const lesseeConfig = ref<lesseeConfig>({
    channelCode: '',
    lesseeName: '',
    ownerShip: '',
    adId: '',
    appKey: '',
    openFlag: '',
    appSecret: ''
  })

  const dialogEditor = ref()

  const dialogVisible = ref(false)
  const type = ref(true)
  const loading = ref(false)

  const save = () => {
    if (dialogEditor.value) {
      loading.value = true
      if (type.value) {
        if (lesseeConfig.value.id) {
          delete lesseeConfig.value.id
        }
      }
      dialogEditor.value.validate((valid: any) => {
        if (valid) {
          lesseeSave(json2Form(lesseeConfig.value)).then(() => {
            dialogEditor.value.resetFields()
            setTimeout(() => {
              loading.value = false
            }, 2000)
            fn()
            close(type.value)
          }).catch(err => {
            setTimeout(() => {
              loading.value = false
            }, 2000)
          })
        } else {
          loading.value = false
          return false
        }
      })
    }
  }
  const close = (bool: boolean) => {
    type.value = bool
    dialogVisible.value = !dialogVisible.value
  }

  const reset = () => {
    setTimeout(() => {
      dialogEditor.value.resetFields()
    })
  }
  const cancel = () => {
    dialogVisible.value = !dialogVisible.value
    reset()
  }
  return { lesseeConfig, dialogVisible, dialogEditor, save, close, reset, cancel, loading }
}
