<template>
  <div class="earn-container report">
    <header-title title="收益情况"></header-title>
    <div class="earn">
      <div class="list">
        <p>昨日收益（元）</p>
        <p>{{ ProfitData.yesterdayProfit }}</p>
      </div>
      <div class="list">
        <p>最近一周收益（元）</p>
        <p>{{ ProfitData.sevenDaysProfit }}</p>
      </div>
      <div class="list">
        <p>最近3个月收益（元）</p>
        <p>{{ ProfitData.threeMonthsProfit }}</p>
      </div>
      <div class="list">
        <p>累计收益（元）</p>
        <p>{{ ProfitData.lesseeProfitTotal }}</p>
      </div>
    </div>
  </div>
  <p class="line"></p>
  <div class="report">
    <header-title title="数据总览"></header-title>
    <div class="data-review">
      <div class="data-list">
        <div class="data-list-right">
          <p>曝光量（次）</p>
          <p>{{ countData.impressionCount }}</p>
        </div>
      </div>
      <div class="data-list">
        <div class="data-list-right">
          <p>点击量（次）</p>
          <p>{{ countData.clickCount }}</p>
        </div>
      </div>
      <div class="data-list">
        <div class="data-list-right">
          <p>点击率（%）</p>
          <p>{{ countData.clickRate }}</p>
        </div>
      </div>
    </div>
    <el-select
      size="mini"
      class="select"
      v-model="dateType"
      placeholder="请选择"
    >
      <el-option
        v-for="item in dateOption"
        :key="item.value"
        :label="item.value"
        :value="item.key"
      >
      </el-option>
    </el-select>
    <chart :data="earnData" :option="option"></chart>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from 'vue'
import { chartType, profitData, countData } from '@/types/index'
import { lesseeProfit, lesseeProfitView, lesseeProfitDate } from '@/utils/index'
import { lineOption } from './option'
import headerTitle from '@/components/headerTitle.vue'
import chart from '@/components/chart.vue'
import json2Form from '@/utils/common'
export default defineComponent({
  name: 'Home',
  data() {
    return {}
  },
  components: {
    headerTitle,
    chart
  },
  setup() {
    let ownerShip = ref<string>('')
    //图表数据
    const earnData = ref<chartType>({
      xAxis: {
        data: []
      },
      series: []
    })
    const option = ref(lineOption)
    let ProfitData = reactive<profitData>({
      sevenDaysProfit: '',
      lesseeProfitTotal: '',
      threeMonthsProfit: '',
      yesterdayProfit: ''
    })

    let countData = reactive<countData>({
      clickCount: '',
      clickRate: '',
      impressionCount: ''
    })
    const dateOption = ref([])
    // 日期
    let dateType = ref('yesterday')

    onMounted(() => {
      lesseeProfitDate().then((res: any) => {
        dateOption.value = res.dateType
      })
      getOwnship()
    })
    const getOwnship = () => {
      window.addEventListener(
        'message',
        function (event) {
          const data = event.data
          switch (data.cmd) {
            // 跳转菜单的参数
            case 'commonInfo':
            case 'tabsChange':
              ownerShip.value = data.params.ownership
              getData()
              geCharttData()
              break
          }
        },
        false
      )
      if (!ownerShip.value) {
        window.parent.postMessage(
          {
            cmd: 'getCommonInfo' // 标识不可更改
          },
          '*'
        )
      }
    }

    watch(dateType, () => {
      geCharttData()
    })
    //获取收益数据
    const getData = () => {
      lesseeProfit(json2Form({ ownerShip: ownerShip.value })).then(
        (res: any) => {
          if (res) {
            ProfitData.sevenDaysProfit = res.sevenDaysProfit
            ProfitData.yesterdayProfit = res.yesterdayProfit
            ProfitData.threeMonthsProfit = res.threeMonthsProfit
            ProfitData.lesseeProfitTotal = res.lesseeProfitTotal
          }
        }
      )
    }
    //获取列表数据
    const geCharttData = () => {
      let chartList: chartType = {
        xAxis: { data: [] },
        series: []
      }

      lesseeProfitView(
        json2Form({ dateType: dateType.value, ownerShip: ownerShip.value })
      ).then((res: any) => {
        const impressionCount: any[] = [],
          clickCount: any[] = [],
          clickRate: any[] = []

        countData.clickCount = res.data.clickCount
        countData.clickRate = res.data.clickRate
        countData.impressionCount = res.data.impressionCount

        res.chart &&
          res.chart.forEach((item: any) => {
            chartList.xAxis.data.push(item.date)
            impressionCount.push(item.impressionCount)
            clickCount.push(item.clickCount)
            clickRate.push(item.clickRate)
          })
        chartList.series = [
          {
            name: '点击率',
            type: 'line',
            data: clickRate
          },
          {
            name: '点击量',
            type: 'line',
            data: clickCount
          },
          {
            name: '曝光量',
            type: 'line',
            data: impressionCount
          }
        ]

        earnData.value = chartList
      })
    }
    return {
      earnData,
      option,
      dateOption,
      ProfitData,
      dateType,
      countData
    }
  }
})
</script>
<style lang="scss">
.earn-container {
  .earn {
    margin-top: 23px;
    display: flex;
    .list {
      width: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      P:first-child {
        font-size: 18px;
        color: #666666;
        margin-bottom: 18px;
      }
      p:last-child {
        color: #ffc165;
        font-size: 30px;
      }
    }
  }
}

.data-review {
  display: flex;
  width: 70%;
  margin-left: 30px;
  margin-bottom: 50px;
  .data-list {
    width: 33.3%;
    display: flex;
    justify-content: center;
    align-items: center;
    .data-list-right {
      display: flex;
      margin-left: 24px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      P:first-child {
        font-size: 18px;
        color: #666666;
        margin-bottom: 9px;
      }
      p:last-child {
        color: #9a7743;
        font-size: 30px;
      }
    }
  }
  .data-list:nth-child(2) {
    .data-list-right {
      p:last-child {
        color: #566bb9;
      }
    }
  }
  .data-list:nth-child(3) {
    .data-list-right {
      p:last-child {
        color: #b04780;
      }
    }
  }
}
.line {
  height: 18px;
}
#app {
  background: #f2f2f2 !important;
  padding: 0;
}
.report {
  position: relative;
  padding: 14px 14px 30px;
  background: #fff;
  overflow: hidden;
}
.select {
  position: absolute;
  right: 36px;
  top: 62px;
}
</style>
