<template>
  <div class="home">
    <header-title title="租户广告位配置"></header-title>
    <div class="header">
      <el-form
        :inline="true"
        :model="formInline"
        size="mini"
        ref="ruleForm"
        class="header-left"
      >
        <el-form-item label="渠道类型">
          <el-select
            clearable
            v-model="formInline.channel"
            placeholder="请选择"
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in channel"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="租户名称">
          <el-input
            clearable
            @focus="focus"
            @clear="clear"
            ref="inputRef"
            v-model="formInline.lesseeName"
            placeholder="请输入租户名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="是否启用">
          <el-select
            clearable
            v-model="formInline.isEnable"
            placeholder="请选择"
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in isEnable"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="header-right">
        <el-button size="small" type="primary" @click="onSubmit"
          >查 询</el-button
        >
        <el-button size="small" @click="add">新 增</el-button>
      </div>
    </div>
    <data-table
      :headerData="headerData"
      @setPage="setPage"
      @editor="editor"
      :tableData="list"
      :isEditor="true"
      :height="h"
      :totalElements="totalElements"
    ></data-table>
    <el-dialog
      title="新增/修改广告配置"
      v-model="dialogVisible"
      :close-on-click-modal="false"
      :width="800"
      :before-close="handleClose"
    >
      <el-form
        :inline="true"
        :model="productConfig"
        :rules="rules"
        label-position="right"
        label-width="150px"
        size="mini"
        ref="dialogEditor"
        class="demo-form-inline"
      >
        <el-form-item label="租户名称" prop="lesseeName">
          <el-input
            ref="inputAddRef"
            @focus="addFocus"
            @clear="clear"
            v-model="productConfig.lesseeName"
            placeholder="请输入租户名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="广告位分辨率宽度" prop="adWidth">
          <el-input
            v-model="productConfig.adWidth"
            placeholder="请输入广告位分辨率宽度"
          ></el-input>
        </el-form-item>
        <el-form-item label="广告位分辨率高度" prop="adHeight">
          <el-input
            v-model="productConfig.adHeight"
            placeholder="请输入广告位分辨率高度"
          ></el-input>
        </el-form-item>
        <el-form-item label="页面" prop="pageCode">
          <el-input
            ref="pageRef"
            @focus="addPageCode"
            @clear="clearPageCode"
            v-model="productConfig.pageName"
            placeholder="请输入页面"
          ></el-input>
        </el-form-item>
        <el-form-item label="广告类型" prop="adTypeCode">
          <el-select
            clearable
            v-model="productConfig.adTypeCode"
            placeholder="请选择广告类型"
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in adType"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否启用">
          <el-select v-model="productConfig.isEnable" placeholder="请选择">
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in isEnable"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="投放时间" prop="timeLimit">
          <el-radio-group v-model="productConfig.timeLimit">
            <el-radio label="0">不限</el-radio>
            <el-radio label="1">自定义</el-radio>
          </el-radio-group>
        </el-form-item>
        <br />
        <el-form-item
          class="datePick"
          prop="startTime"
          v-if="productConfig.timeLimit === '1'"
        >
          <el-time-picker
            is-range
            v-model="time"
            format="HH:mm"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
          >
          </el-time-picker>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="save" :loading="loading"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
    <tenant-data-config
      ref="tanantConfig"
      type="1"
      @setTanantCheck="setTanantCheck"
    ></tenant-data-config>
    <page-config
      ref="pageAddRef"
      :channelCode="channelCode"
      @setPageCheck="setPageCheck"
    ></page-config>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
  nextTick
} from 'vue'
import { adConfig, tenantConfig } from '@/types/index'
import { advertList, getAdvertReq } from '@/utils/index'
import headerTitle from '@/components/headerTitle.vue'
import dataTable from '@/components/dataTable.vue'
import dialogFn from '@/views/tenantMsgConfig/index'
import tenantDataConfig from '@/components/tenantDataConfig.vue'
import pageConfig from '@/components/pageConfig.vue'
import json2Form, { setHeight } from '@/utils/common'
export default defineComponent({
  name: 'Home',
  data() {
    return {
      headerData: [
        { value: 'channelName', text: '渠道名称' },
        { value: 'lesseeName', text: '租户名称' },
        { value: 'ownerShip', text: '租户标识' },
        { value: 'timeLimit', text: '投放时段' },
        { value: 'size', text: '尺寸' },
        { value: 'isEnable', text: '是否启用' },
        { value: 'pageName', text: '页面名称' },
        { value: 'pageCode', text: '页面编码' },
        { value: 'adTypeName', text: '广告类型名称' },
        { value: 'adTypeCode', text: '广告类型编码' }
      ],
      rules: {
        lesseeName: [
          { required: true, message: '请输入租户名称', trigger: 'change' }
        ],
        adWidth: [
          { required: true, message: '请输入广告位分辨率宽度', trigger: 'blur' }
        ],
        adHeight: [
          { required: true, message: '请输入广告位分辨率高度', trigger: 'blur' }
        ],
        pageCode: [
          { required: true, message: '请选择页面编码', trigger: 'change' }
        ],
        adTypeCode: [
          { required: true, message: '请选择广告类型', trigger: 'change' }
        ],
        timeLimit: [
          { required: true, message: '请选择处理状态类型', trigger: 'change' }
        ],
        startTime: [
          { required: true, message: '请选择时间', trigger: 'change' }
        ]
      }
    }
  },
  components: {
    headerTitle,
    dataTable,
    tenantDataConfig,
    pageConfig
  },
  setup() {
    const formInline = reactive<tenantConfig>({
      lesseeName: '',
      channel: '',
      ownerShip: '',
      isEnable: '',
      p: 1,
      ps: 20
    })

    //组件ref
    const ruleForm = ref()
    //基础信息
    const config = reactive<adConfig>({
      channel: [],
      isEnable: [],
      adType: [],
      page: [],
      ownerShip: []
    })

    let list = ref<any[]>([])
    let totalElements = ref<number>(0)
    let h = ref()
    const type = ref(false)
    const tanantConfig = ref()
    const inputRef = ref()
    const inputAddRef = ref()
    const channelCode = ref()

    onMounted(() => {
      h.value = setHeight(true)
      getAdvertReq().then((res: any) => {
        const data: adConfig = res
        for (let key in data) {
          config[key] = data[key]
        }
      })
      getData()
    })

    //获取列表数据
    const getData = () => {
      list.value = []
      advertList(json2Form(formInline)).then((res: any) => {
        if (res.content) {
          list.value = res.content
          totalElements.value = res.totalElements
        }
      })
    }
    //新增编辑
    const dialog = dialogFn(getData)
    // 查询
    const onSubmit = () => {
      if (ruleForm.value) {
        ruleForm.value.validate((valid: any) => {
          if (valid) {
            formInline.p = 1
            getData()
          } else {
            return false
          }
        })
      }
    }

    //新增
    const add = () => {
      channelCode.value = ''
      dialog.reset()
      dialog.close(true)
    }

    //
    const setPage = (page: number) => {
      formInline.p = page
      getData()
    }

    const editor = (res: any) => {
      dialog.close(false)
      nextTick(() => {
        const data = JSON.parse(JSON.stringify(res))
        delete data.createUser
        delete data.createdTime
        delete data.updateUser
        delete data.updatedTime
        delete data.createUserName
        delete data.startTimeStr
        delete data.endTimeStr

        dialog.productConfig.value = data
        if (data.startTime) {
          dialog.time.value = [data.startTime, data.endTime]
        } else {
          dialog.time.value = []
        }
        dialog.productConfig.value.startTime = data.startTime
        dialog.productConfig.value.endTime = data.endTime
      })
    }

    // 选中租户
    const setTanantCheck = (data: any, bool: boolean) => {
      if (bool) {
        if (type.value) {
          // eslint-disable-next-line no-constant-condition
          if (dialog.productConfig.value.ownerShip === ' ') {
            dialog.productConfig.value.ownerShip = ''
            dialog.productConfig.value.channel = ''
            dialog.productConfig.value.lesseeName = ''
            dialog.productConfig.value.pageName = ''
          }
        }
      } else {
        if (type.value) {
          dialog.productConfig.value.ownerShip = data.ownership
          dialog.productConfig.value.channel = data.channelCode
          dialog.productConfig.value.lesseeName = data.name
          dialog.productConfig.value.pageName = data.pageName
          channelCode.value = data.channelCode
        } else {
          formInline.lesseeName = data.name
          formInline.ownerShip = data.ownership
        }
      }
    }
    const focus = () => {
      inputRef.value.blur()
      type.value = false
      tanantConfig.value.close()
    }
    const addFocus = () => {
      dialog.productConfig.value.ownerShip = ' '
      inputAddRef.value.blur()
      type.value = true
      tanantConfig.value.close()
    }
    const clear = () => {
      if (type.value) {
        dialog.productConfig.value.ownerShip = ''
        dialog.productConfig.value.channel = ''
        dialog.productConfig.value.lesseeName = ''
        dialog.productConfig.value.pageName = ''
      } else {
        formInline.lesseeName = ''
        formInline.ownerShip = ''
      }
    }
    return {
      ...toRefs(dialog),
      ...toRefs(config),
      formInline,
      onSubmit,
      ruleForm,
      setPage,
      add,
      list,
      totalElements,
      editor,
      h,
      tanantConfig,
      focus,
      addFocus,
      inputRef,
      inputAddRef,
      clear,
      setTanantCheck,
      channelCode
    }
  }
})
</script>
<style lang="scss" scoped>
::v-deep .el-input--suffix .el-input__inner {
  padding-right: 13px;
}
::v-deep .datePick {
  margin-left: 150px;
}
.header {
  overflow: hidden;
  display: flex;
  .header-left {
    flex: 0 0 85%;
  }
  .header-right {
    flex: 0 0 15%;
    text-align: right;
  }
}
</style>
