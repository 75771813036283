/*
 * @Author: your name
 * @Date: 2021-07-07 16:39:55
 * @LastEditTime: 2021-07-21 11:23:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \advertising-platform\src\utils\index.ts
 */
import axios from './http'

const api = 'api'

/**
 * @description: 广告配置
 * @param {FormData} data
 * @return {*}
 */
//新增
export const productSave = (data: FormData) => {
  return axios.post(api + '/productConfig/save', data)
}
//列表
export const productList = (data: FormData) => {
  return axios.post(api + '/productConfig/list', data)
}
//获取配置
export const getReq = () => {
  return axios.get(api + '/productConfig/keyValue')
}

/**
 * @description: 租户基础信息配置
 * @param {*}
 * @return {*}
 */
//新增
export const lesseeSave = (data: FormData) => {
  return axios.post(api + '/lesseeConfig/save', data)
}
//列表
export const lesseeList = (data: FormData) => {
  return axios.post(api + '/lesseeConfig/list', data)
}
//获取配置
export const getLesseeReq = () => {
  return axios.get(api + '/lesseeConfig/keyValue')
}
//获取租户信息
export const searchParticipant = (data: FormData) => {
  return axios.post(api + '/lesseeConfig/searchParticipant', data)
}
//获取本地租户信息
export const getLessee = (data: FormData) => {
  return axios.post(api + '/lesseeConfig/lesseeList', data)
}
/**
 * @description: 租户广告配置
 * @param {*}
 * @return {*}
 */
//新增
export const advertSave = (data: FormData) => {
  return axios.post(api + '/adConfig/save', data)
}
//列表
export const advertList = (data: FormData) => {
  return axios.post(api + '/adConfig/list', data)
}
//获取配置
export const getAdvertReq = () => {
  return axios.get(api + '/adConfig/keyValue')
}

/**
 * @description: 租户广告记录明细
 * @param {*}
 * @return {*}
 */

//列表
export const advertDetailList = (data: FormData) => {
  return axios.post(api + '/adLog/list', data)
}
//获取配置
export const getAdDetailReq = () => {
  return axios.get(api + '/adLog/keyValue')
}

//数据导出
export const adLogExportXls = (data: any, config: any) => {
  return axios.post(api + '/adLog/list/exportXls', data, config)
}

/**
 * @description: 数据统计
 * @param {*}
 * @return {*}
 */

//列表
export const adReport = (data: FormData) => {
  return axios.post(api + '/adReport/lesseeReport/list', data)
}
// 统计图表
export const chartReport = (data: FormData) => {
  return axios.post(api + '/adReport/lesseeReport/chart', data)
}
// 导出
export const exportXls = (data: any, config: any) => {
  return axios.post(api + '/adReport/lesseeReport/exportXls', data, config)
}
// 收益情况
export const lesseeProfit = (data: any) => {
  return axios.post(api + '/adReport/lesseeProfit/info', data)
}
// 收益日期
export const lesseeProfitDate = () => {
  return axios.post(api + '/adReport/lesseeProfit/keyValue')
}

// 数据总览
export const lesseeProfitView = (data: any) => {
  return axios.post(api + '/adReport/lesseeProfit/view', data)
}
