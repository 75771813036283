import { ref, watch } from 'vue'

import { advertSave } from '@/utils/index'
import json2Form from '@/utils/common'
export default function dialog(fn: () => void): any {
  const productConfig = ref({
    lesseeName: '',
    ownerShip: '',
    timeLimit: '0',
    startTime: '',
    isEnable: '1',
    endTime: '',
    adWidth: '',
    adHeight: '',
    pageCode: '',
    pageName: '',
    channel: '',
    adTypeCode: ''
  })

  const dialogEditor = ref()
  const time = ref([])
  const dialogVisible = ref<boolean>(false)
  const pageRef = ref()
  const pageAddRef = ref()
  const type = ref(true)
  const loading = ref(false)

  const save = () => {
    if (dialogEditor.value) {
      loading.value = true
      const data = JSON.parse(JSON.stringify(productConfig.value))
      if (type.value) {
        if (data.id) {
          delete data.id
        }
      }

      delete data.name
      delete data.pageName
      dialogEditor.value.validate((valid: any) => {
        if (valid) {
          advertSave(json2Form(data)).then(() => {
            dialogEditor.value.resetFields()
            setTimeout(() => {
              loading.value = false
            }, 2000)
            fn()
            close(type.value)
          }).catch(err => {
            setTimeout(() => {
              loading.value = false
            }, 2000)
          })
        } else {
          loading.value = false
          return false
        }
      })
    }
  }
  watch(time, (newProps) => {
    if (newProps[0]) {
      productConfig.value.startTime = new Date(newProps[0]).getTime() + ''
      productConfig.value.endTime = new Date(newProps[1]).getTime() + ''
    } else {
      productConfig.value.startTime = ''
      productConfig.value.endTime = ''
    }
  })

  const close = (bool: boolean) => {
    type.value = bool
    dialogVisible.value = !dialogVisible.value
  }

  const reset = () => {
    setTimeout(() => {
      dialogEditor.value.resetFields()
      productConfig.value.startTime = ''
      productConfig.value.endTime = ''
      productConfig.value.pageName = ''
      time.value = []
    })
  }

  const addPageCode = () => {
    productConfig.value.pageName = ' '
    pageRef.value.blur()
    pageAddRef.value.close()
  }
  const clearPageCode = () => {
    productConfig.value.pageCode = ''
  }
  // 选中租户
  const setPageCheck = (data: any, bool: boolean) => {
    if (bool) {
      // eslint-disable-next-line no-constant-condition
      if (productConfig.value.pageCode === ' ') {
        productConfig.value.pageCode = ''
      }
    } else {
      productConfig.value.pageCode = data.pageCode
      productConfig.value.pageName = data.pageName
    }
  }

  return {
    productConfig,
    dialogVisible,
    dialogEditor,
    save,
    close,
    time,
    reset,
    addPageCode,
    clearPageCode,
    setPageCheck,
    pageRef,
    pageAddRef,
    loading
  }
}
