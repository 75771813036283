
import { ref, computed, defineComponent, watch } from 'vue'
import { changetime } from '@/utils/common'
export default defineComponent({
  name: 'dataTable',
  props: {
    tableData: Object,
    headerData: Object,
    totalElements: Number,
    isEditor: Boolean,
    height: Number,
    checked: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const newTableData = ref()

    const setBackground = () => {
      return 'background:#F2F2F2'
    }
    const currentChange = (page: number) => {
      // eslint-disable-next-line no-debugger
      emit('setPage', page)
    }
    const handleClick = (res: any) => {
      emit('editor', res)
    }
    const getData = (res: any, index: number) => {
      if (newTableData.value) {
        newTableData.value.forEach((element: any) => {
          element.checkbox = false
        })
      }
      newTableData.value[index].checkbox = true
      emit('setCheck', newTableData.value[index])
    }
    watch(
      () => props.tableData,
      (newProps) => {
        if (newProps && newProps.length > 0) {
          newProps.forEach((element: any) => {
            element.checkbox = false
          })
        }
        newTableData.value = newProps
      }
    )

    const getHeight = computed(() => {
      return (
        props.height &&
        props.height -
          document.getElementsByClassName('pagination')[0].clientHeight +
          'px'
      )
    })

    return {
      setBackground,
      currentChange,
      handleClick,
      changetime,
      getHeight,
      getData,
      newTableData
    }
  }
})
