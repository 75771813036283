
import { defineComponent, onMounted, reactive, ref, watch } from 'vue'
import { chartType, profitData, countData } from '@/types/index'
import { lesseeProfit, lesseeProfitView, lesseeProfitDate } from '@/utils/index'
import { lineOption } from './option'
import headerTitle from '@/components/headerTitle.vue'
import chart from '@/components/chart.vue'
import json2Form from '@/utils/common'
export default defineComponent({
  name: 'Home',
  data() {
    return {}
  },
  components: {
    headerTitle,
    chart
  },
  setup() {
    let ownerShip = ref<string>('')
    //图表数据
    const earnData = ref<chartType>({
      xAxis: {
        data: []
      },
      series: []
    })
    const option = ref(lineOption)
    let ProfitData = reactive<profitData>({
      sevenDaysProfit: '',
      lesseeProfitTotal: '',
      threeMonthsProfit: '',
      yesterdayProfit: ''
    })

    let countData = reactive<countData>({
      clickCount: '',
      clickRate: '',
      impressionCount: ''
    })
    const dateOption = ref([])
    // 日期
    let dateType = ref('yesterday')

    onMounted(() => {
      lesseeProfitDate().then((res: any) => {
        dateOption.value = res.dateType
      })
      getOwnship()
    })
    const getOwnship = () => {
      window.addEventListener(
        'message',
        function (event) {
          const data = event.data
          switch (data.cmd) {
            // 跳转菜单的参数
            case 'commonInfo':
            case 'tabsChange':
              ownerShip.value = data.params.ownership
              getData()
              geCharttData()
              break
          }
        },
        false
      )
      if (!ownerShip.value) {
        window.parent.postMessage(
          {
            cmd: 'getCommonInfo' // 标识不可更改
          },
          '*'
        )
      }
    }

    watch(dateType, () => {
      geCharttData()
    })
    //获取收益数据
    const getData = () => {
      lesseeProfit(json2Form({ ownerShip: ownerShip.value })).then(
        (res: any) => {
          if (res) {
            ProfitData.sevenDaysProfit = res.sevenDaysProfit
            ProfitData.yesterdayProfit = res.yesterdayProfit
            ProfitData.threeMonthsProfit = res.threeMonthsProfit
            ProfitData.lesseeProfitTotal = res.lesseeProfitTotal
          }
        }
      )
    }
    //获取列表数据
    const geCharttData = () => {
      let chartList: chartType = {
        xAxis: { data: [] },
        series: []
      }

      lesseeProfitView(
        json2Form({ dateType: dateType.value, ownerShip: ownerShip.value })
      ).then((res: any) => {
        const impressionCount: any[] = [],
          clickCount: any[] = [],
          clickRate: any[] = []

        countData.clickCount = res.data.clickCount
        countData.clickRate = res.data.clickRate
        countData.impressionCount = res.data.impressionCount

        res.chart &&
          res.chart.forEach((item: any) => {
            chartList.xAxis.data.push(item.date)
            impressionCount.push(item.impressionCount)
            clickCount.push(item.clickCount)
            clickRate.push(item.clickRate)
          })
        chartList.series = [
          {
            name: '点击率',
            type: 'line',
            data: clickRate
          },
          {
            name: '点击量',
            type: 'line',
            data: clickCount
          },
          {
            name: '曝光量',
            type: 'line',
            data: impressionCount
          }
        ]

        earnData.value = chartList
      })
    }
    return {
      earnData,
      option,
      dateOption,
      ProfitData,
      dateType,
      countData
    }
  }
})
