<template>
  <div class="home">
    <header-title title="广告记录明细"></header-title>
    <div class="header">
      <el-form
        :inline="true"
        :model="formInline"
        size="mini"
        ref="ruleForm"
        class="header-left"
      >
        <el-form-item label="渠道类型">
          <el-select
            clearable
            v-model="formInline.channel"
            placeholder="请选择渠道类型"
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in channel"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="广告类型">
          <el-select
            clearable
            v-model="formInline.adType"
            placeholder="请选择广告类型"
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in adType"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="页面名称">
          <el-select
            clearable
            filterable
            v-model="formInline.pageCode"
            placeholder="请选择页面"
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in pageCode"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否点击">
          <el-select
            clearable
            v-model="formInline.clickFlag"
            placeholder="请选择"
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in clickTag"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择时间">
          <el-date-picker
            v-model="time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="YYYY-MM-DD"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="租户名称">
          <el-input
            clearable
            @focus="focus"
            @clear="clear"
            ref="inputRef"
            v-model="formInline.ownerName"
            placeholder="请输入租户名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="header-left">
        <el-button size="small" type="primary" @click="onSubmit('ruleForm')"
          >查 询</el-button
        >
        <el-tooltip
          class="item"
          effect="dark"
          content="最多导出5000条数据"
          placement="top-end"
        >
          <el-button @click="exportData" size="small" type="primary"
            >导 出</el-button
          >
        </el-tooltip>
      </div>
    </div>
    <data-table
      :headerData="headerData"
      @setPage="setPage"
      :tableData="list"
      :totalElements="totalElements"
      :isEditor="false"
      :height="h"
    ></data-table>
    <tenant-data-config
      ref="tanantConfig"
      type="1"
      @setTanantCheck="setTanantCheck"
    ></tenant-data-config>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, toRefs, watch } from 'vue'
import { adConfig, adlog } from '@/types/index'
import { advertDetailList, getAdDetailReq, adLogExportXls } from '@/utils/index'
import headerTitle from '@/components/headerTitle.vue'
import dataTable from '@/components/dataTable.vue'
import json2Form, { setHeight, getRegTime } from '@/utils/common'
import tenantDataConfig from '@/components/tenantDataConfig.vue'
export default defineComponent({
  name: 'Home',
  data() {
    return {
      headerData: [
        { value: 'id', text: '记录id' },
        { value: 'channelName', text: '渠道名称' },
        { value: 'ownerName', text: '租户名称' },
        { value: 'adId', text: '广告位id' },
        { value: 'adType', text: '广告类型编码' },
        { value: 'adTypeName', text: '广告类型名称' },
        { value: 'pageName', text: '页面名称' },
        { value: 'userTag', text: '用户标识' },
        { value: 'clickFlag', text: '是否点击' },
        { value: 'exposureTime', text: '曝光时长' },
        { value: 'clickTime', text: '点击时间' },
        { value: 'createdTime', text: '创建时间' }
      ]
    }
  },
  components: {
    headerTitle,
    dataTable,
    tenantDataConfig
  },
  setup() {
    const formInline = reactive<adlog>({
      ownerName: '',
      adType: '',
      pageCode: '',
      clickFlag: '',
      ownerShip: '',
      beginDate: '',
      endDate: '',
      p: 1,
      ps: 20
    })

    //组件ref
    const ruleForm = ref()
    //基础信息
    //基础信息
    const config = reactive<adConfig>({
      channel: [],
      clickTag: [],
      adType: [],
      pageCode: [],
      ownerShip: []
    })
    let list: any = ref<any[]>([])
    // 数据总条数
    let totalElements = ref<number>(0)
    // 选择日期
    const time = ref<Date[]>([])
    // 图表高度
    let h = ref()
    const type = ref(false)
    const tanantConfig = ref()
    const inputRef = ref()

    watch(time, (newProps) => {
      if (newProps) {
        formInline.beginDate = getRegTime(new Date(newProps[0]).getTime())
        formInline.endDate = getRegTime(new Date(newProps[1]).getTime())
      } else {
        formInline.beginDate = ''
        formInline.endDate = ''
      }
    })

    onMounted(() => {
      h.value = setHeight(true)
      getAdDetailReq().then((res: any) => {
        const data: adConfig = res
        for (let key in data) {
          config[key] = data[key]
        }
      })
      getData()
    })

    //获取列表数据
    const getData = () => {
      list.value = []
      advertDetailList(json2Form(formInline)).then((res: any) => {
        if (res.content) {
          list.value = list.value.concat(res.content)
          totalElements.value = res.totalElements
        }
      })
    }

    // 查询
    const onSubmit = () => {
      if (ruleForm.value) {
        ruleForm.value.validate((valid: any) => {
          if (valid) {
            formInline.p = 1
            getData()
          } else {
            return false
          }
        })
      }
    }

    //
    const setPage = (page: number) => {
      formInline.p = page
      getData()
    }

    // 选中租户
    const setTanantCheck = (data: any, bool: boolean) => {
      if (!bool) {
        if (!type.value) {
          formInline.ownerName = data.name
          formInline.ownerShip = data.ownership
        }
      }
    }
    const focus = () => {
      inputRef.value.blur()
      tanantConfig.value.close()
    }
    // 清除数据
    const clear = () => {
      formInline.ownerName = ''
      formInline.ownerShip = ''
    }
    // 导出数据
    const exportData = () => {
      let res = JSON.parse(JSON.stringify(formInline))
      delete res.ps
      delete res.p
      adLogExportXls(json2Form(res), { responseType: 'blob' }).then(
        (res: any) => {
          let blob = new Blob([res], {
            type: 'application/vnd.ms-excel'
          })
          const fileName = '数据报表.csv'
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href) // 释放URL对象
          document.body.removeChild(elink)
        }
      )
    }
    return {
      ...toRefs(config),
      formInline,
      onSubmit,
      ruleForm,
      setPage,
      list,
      totalElements,
      time,
      h,
      tanantConfig,
      focus,
      inputRef,
      clear,
      setTanantCheck,
      exportData
    }
  }
})
</script>
<style lang="scss" scoped>
::v-deep .el-input--suffix .el-input__inner {
  padding-right: 13px;
}
.header {
  overflow: hidden;
  display: flex;
  .header-left {
    flex: 0 0 85%;
  }
  .header-right {
    flex: 0 0 15%;
    text-align: right;
  }
}
</style>
