/*
 * @Author: your name
 * @Date: 2021-07-07 16:36:29
 * @LastEditTime: 2021-07-23 14:17:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \advertising-platform\src\router\index.ts
 */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/home/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/index',
    name: 'Home',
    component: Home
  },
  {
    path: '/tenantConfig',
    name: 'tenantConfig',
    component: () =>
      import(
        /* webpackChunkName: "tenantConfig" */ '../views/tenantConfig/tenantConfig.vue'
      )
  },
  {
    path: '/tenantMsgConfig',
    name: 'tenantMsgConfig',
    component: () =>
      import(
        /* webpackChunkName: "tenantMsgConfig" */ '../views/tenantMsgConfig/index.vue'
      )
  },
  {
    path: '/adverDetail',
    name: 'adverDetail',
    component: () =>
      import(
        /* webpackChunkName: "adverDetail" */ '../views/adverDetail/index.vue'
      )
  },
  {
    path: '/advertReport',
    name: 'advertReport',
    component: () =>
      import(
        /* webpackChunkName: "advertReport" */ '../views/advertReport/index.vue'
      )
  },
  {
    path: '/dataReport',
    name: 'dataReport',
    component: () =>
      import(
        /* webpackChunkName: "dataReport" */ '../views/advertReport/dataReport.vue'
      )
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
