/*
 * @Author: your name
 * @Date: 2021-07-09 09:50:54
 * @LastEditTime: 2021-07-22 11:32:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \advertising-platform\src\utils\common.ts
 */
export default function json2Form(json: any) {
  const formData = new FormData()
  Object.keys(json).forEach((key) => formData.append(key, json[key]))
  return formData
}
export function changetime(data: number) {
  return new Date(data).getHours() + ':' + new Date(data).getMinutes()
}

export function setHeight(bool: boolean) {
  const dh = document.body.clientHeight
  const h = document.getElementsByClassName('header')[0].clientHeight
  const th = document.getElementsByClassName('title')[0].clientHeight
  if (bool) {
    return dh - h - th - 105
  } else {
    return dh - h - th - 400
  }
}
export function getRegTime(data: number) {
  const y = new Date(data).getFullYear()
  const m =
    new Date(data).getMonth() > 9
      ? new Date(data).getMonth()
      : '0' + (new Date(data).getMonth() + 1)
  const d =
    new Date(data).getDate() > 9
      ? new Date(data).getDate()
      : '0' + new Date(data).getDate()
  return y + '-' + m + '-' + d
}
