<template>
  <div class="home">
    <header-title title="广告统计报表信息"></header-title>
    <div class="header">
      <el-form
        :inline="true"
        :model="formInline"
        size="mini"
        ref="ruleForm"
        label-width="100px"
        class="demo-form-inline"
      >
        <el-form-item label="渠道类型">
          <el-select
            clearable
            v-model="formInline.channelCode"
            placeholder="请选择渠道类型"
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in channel"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="选择时间">
          <el-date-picker
            v-model="time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :disabledDate="disabledDateFn"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit('ruleForm')"
            >查 询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <chart ref="chart" :data="data" :option="option"></chart>
    <div class="tatol">
      <span>明细数据【收入合计：{{ lesseeProfit }}】</span>
      <el-button @click="exportEx" size="small" type="primary">导 出</el-button>
    </div>
    <data-table
      :headerData="headerData"
      @setPage="setPage"
      :tableData="list"
      :totalElements="totalElements"
      :height="h"
      :isEditor="false"
    ></data-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, toRefs, watch } from 'vue'
import { adConfig, advertReportSearch, chartType } from '@/types/index'
import {
  chartReport,
  lesseeProfitDate,
  adReport,
  exportXls
} from '@/utils/index'
import { lineOption } from './option'
import headerTitle from '@/components/headerTitle.vue'
import chart from '@/components/chart.vue'
import dataTable from '@/components/dataTable.vue'
import json2Form, { setHeight, getRegTime } from '@/utils/common'
export default defineComponent({
  name: 'Home',
  data() {
    return {
      headerData: [
        { value: 'channelName', text: '渠道名称' },
        { value: 'date', text: '时间' },
        { value: 'impressionCount', text: '曝光量' },
        { value: 'clickCount', text: '点击量' },
        { value: 'clickRate', text: '点击率' },
        { value: 'lesseeProfit', text: '收入' }
      ]
    }
  },
  components: {
    headerTitle,
    dataTable,
    chart
  },
  setup() {
    const formInline = reactive<advertReportSearch>({
      ownerShip: '',
      channelCode: '',
      beginDate: '',
      endDate: '',
      p: 1,
      ps: 20
    })

    //组件ref
    const ruleForm = ref()
    //基础信息
    const config = reactive<adConfig>({
      channel: []
    })
    // 图表
    let list = ref<any[]>([])
    // 总条数
    let totalElements = ref<number>(0)
    let lesseeProfit = ref<string>()
    const time = ref([])
    const chart = ref()
    //图表数据
    const data = ref<chartType>({
      xAxis: {
        data: []
      },
      series: []
    })
    const option = ref<any>(lineOption)
    let h = ref()

    watch(time, (newProps) => {
      console.log(newProps)

      if (newProps) {
        formInline.beginDate = getRegTime(new Date(newProps[0]).getTime())
        formInline.endDate = getRegTime(new Date(newProps[1]).getTime())
      } else {
        formInline.beginDate = ''
        formInline.endDate = ''
      }
    })

    onMounted(() => {
      h.value = setHeight(false)
      lesseeProfitDate().then((res: any) => {
        const data: adConfig = res
        for (let key in data) {
          config[key] = data[key]
        }
      })
      getOwnship()
    })
    const getOwnship = () => {
      window.addEventListener(
        'message',
        function (event) {
          const data = event.data
          switch (data.cmd) {
            // 跳转菜单的参数
            case 'commonInfo':
            case 'tabsChange':
              formInline.ownerShip = data.params.ownership
              getData()
              geCharttData()
              break
          }
        },
        false
      )
      if (!formInline.ownerShip) {
        window.parent.postMessage(
          {
            cmd: 'getCommonInfo' // 标识不可更改
          },
          '*'
        )
      }
    }
    //获取列表数据
    const getData = () => {
      list.value = []
      adReport(json2Form(formInline)).then((res: any) => {
        if (res.content) {
          list.value = list.value.concat(res.content)
          totalElements.value = res.totalElements
        }
      })
    }
    //获取列表数据
    const geCharttData = () => {
      let chartList: chartType = {
        xAxis: { data: [] },
        series: []
      }
      let res = JSON.parse(JSON.stringify(formInline))
      delete res.ps
      delete res.p
      chartReport(json2Form(res)).then((res: any) => {
        const impressionCount: any[] = [],
          clickCount: any[] = [],
          clickRate: any[] = []

        lesseeProfit.value = res.lesseeProfit
        res.chart &&
          res.chart.forEach((item: any, index: number) => {
            chartList.xAxis.data.push(item.date)
            impressionCount.push(item.impressionCount)
            clickCount.push(item.clickCount)
            clickRate.push(item.clickRate)
          })
        chartList.series = [
          {
            name: '点击率',
            type: 'line',
            data: clickRate
          },
          {
            name: '点击量',
            type: 'line',
            data: clickCount
          },
          {
            name: '曝光量',
            type: 'line',
            data: impressionCount
          }
        ]

        data.value = chartList
      })
    }
    // 查询
    const onSubmit = () => {
      if (ruleForm.value) {
        ruleForm.value.validate((valid: any) => {
          if (valid) {
            formInline.p = 1
            getData()
            geCharttData()
          } else {
            return false
          }
        })
      }
    }

    //分页
    const setPage = (page: number) => {
      formInline.p = page
      getData()
    }

    // 导出
    const exportEx = () => {
      let res = JSON.parse(JSON.stringify(formInline))
      delete res.ps
      delete res.p
      exportXls(json2Form(res), { responseType: 'blob' }).then((res: any) => {
        let blob = new Blob([res], {
          type: 'application/vnd.ms-excel'
        })
        const fileName = '数据报表.csv'
        const elink = document.createElement('a')
        elink.download = fileName
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href) // 释放URL 对象
        document.body.removeChild(elink)
      })
    }
    // 限制选择当前时间及当前时间之前的日期
    const disabledDateFn = (time: Date) => {
      return time.getTime() > Date.now() - 8.64e7
    }
    return {
      ...toRefs(config),
      formInline,
      onSubmit,
      ruleForm,
      setPage,
      list,
      totalElements,
      time,
      data,
      option,
      h,
      lesseeProfit,
      exportEx,
      chart,
      disabledDateFn
    }
  }
})
</script>
<style lang="scss" scoped>
::v-deep .el-input--suffix .el-input__inner {
  padding-right: 13px;
}
.tatol {
  color: #414141;
  margin: 0 0 15px 39px;
  font-size: 12px;
  span {
    float: left;
    margin-top: 10px;
  }
  button {
    float: right;
  }
  &::after {
    clear: both;
    display: block;
    content: '';
  }
}
</style>
