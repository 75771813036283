
import { defineComponent, reactive, ref, watch } from 'vue'
import { ElMessage } from 'element-plus'
import { searchLessee } from '@/types/index'
import { productList } from '@/utils/index'
import dataTable from '@/components/dataTable.vue'
import json2Form from '@/utils/common'
export default defineComponent({
  name: 'Home',
  data() {
    return {
      headerData: [
        { value: 'pageName', text: '页面名称' },
        { value: 'pageCode', text: '页面编码' },
        { value: 'channelName', text: '渠道名称' }
      ]
    }
  },
  components: {
    dataTable
  },
  props: {
    type: String,
    channelCode: String
  },
  setup(props, { emit }) {
    console.log(props.channelCode)

    const formInline = reactive({
      pageName: '',
      channelCode: '',
      p: 1,
      ps: 20
    })

    const ruleForm = ref()
    let tanantDialog = ref(false)

    let list: any = ref<any[]>([])
    let totalElements = ref<number>(0)
    let checkData = ref()

    // onMounted(() => {})
    watch(
      () => props.channelCode,
      (newprops) => {
        formInline.channelCode = newprops || ''
      }
    )
    //获取列表数据
    const getData = () => {
      list.value = []
      productList(json2Form(formInline)).then((res: any) => {
        if (res.content) {
          list.value = list.value.concat(res.content)
          totalElements.value = res.totalElements
        }
      })
    }

    // 查询
    const onSubmit = () => {
      if (ruleForm.value) {
        ruleForm.value.validate((valid: any) => {
          if (valid) {
            formInline.p = 1
            getData()
          } else {
            return false
          }
        })
      }
    }

    const save = () => {
      formInline.pageName = ''
      if (checkData.value) {
        emit('setPageCheck', checkData.value, false)
      } else {
        ElMessage.error('请选择')
        return false
      }
      tanantDialog.value = !tanantDialog.value
    }
    const setCheck = (data: any) => {
      checkData.value = data
    }

    const close = () => {
      tanantDialog.value = !tanantDialog.value
      if (tanantDialog.value) {
        getData()
      }
    }
    const handleClose = () => {
      formInline.pageName = ''
      tanantDialog.value = !tanantDialog.value
      emit('setTanantCheck', null, true)
    }
    //分页
    const setPage = (page: number) => {
      formInline.p = page
      getData()
    }

    return {
      formInline,
      onSubmit,
      ruleForm,
      setPage,
      tanantDialog,
      list,
      totalElements,
      setCheck,
      save,
      close,
      handleClose
    }
  }
})
